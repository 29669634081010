import React, { useEffect, useState } from 'react';
import InputWithIcon from './InputWithIcon';
import Button from './button';
import { LuUpload } from 'react-icons/lu';
import { useOutletContext } from "react-router-dom";
import { FaAlignLeft, FaAlignRight } from "react-icons/fa6";
import { MdDragIndicator } from "react-icons/md";

export default function ImageEditor({ emitToolBarData, reset, annotationData, handleExport }) {
  const [values, setValues] = useState({ scale: 100, rotate: 0, annotate: false, align: 'left', move: false, fontSize: 0 });

  const handleZoomIn = () => setValues(prev => ({ ...prev, scale: Math.min(prev.scale + 5, 1000) }));

  const handleZoomOut = () => setValues(prev => ({ ...prev, scale: Math.max(prev.scale - 5, 20) }));

  const handleRotateFront = () => setValues(prev => ({ ...prev, rotate: prev.rotate === 270 ? 0 : prev.rotate + 90 }));

  const handleRotateBack = () => setValues(prev => ({ ...prev, rotate: prev.rotate === -270 ? 0 : prev.rotate - 90 }));

  const handleAlignRight = () => setValues(prev => ({ ...prev, align: 'right' }));

  const handleAlignLeft = () => setValues(prev => ({ ...prev, align: 'left' }));

  const handleFontSize = (e) => setValues(prev => ({ ...prev, fontSize: parseInt(e.target.value) }));

  const { open } = useOutletContext();

  useEffect(() => {
    emitToolBarData(values);
  }, [values]);

  useEffect(() => {
    setValues({ ...values, ...annotationData })
  }, [annotationData])

  return (
    <div className='overflow-hidden'>
      <div className={`flex flex-nowrap pt-2 ${open ? ' gap-2 ' : ' gap-3.5 '} bg-white border-b border-gray-200`}>
        <Button onClick={() => setValues(prev => ({ ...prev, annotate: !prev.annotate, move: false }))}>
          <span className='flex justify-center'>
            <img src={`/assets/toolbaricon/spl${values.annotate ? 'active' : ''}.svg`} className='w-5 h-5' alt='SPL' />
          </span>
          <span className={`block font-light mt-2 ${values.annotate ? ' text-primary' : ''}`}>SPL</span>
        </Button>
        <Button onClick={() => console.log('selected')} disabled={true}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/selected.svg' className='w-5 h-5' alt='Selected' />
          </span>
          <span className='block font-light mt-2'>Selected</span>
        </Button>
        <Button onClick={() => console.log('unselected')} disabled={true}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/unselected.svg' className='w-5 h-5' alt='Unselected' />
          </span>
          <span className='block font-light mt-2'>Unselected</span>
        </Button>
        <Button onClick={() => reset()}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/reset.svg' className='w-5 h-5' alt='Reset' />
          </span>
          <span className='block font-light mt-2'>Reset</span>
        </Button>
        <Button onClick={() => setValues(prev => ({ ...prev, move: !prev.move, annotate: false }))}>
          <span className='flex justify-center'>
            {/* <img src={`/assets/toolbaricon/spl${values.annotate ? 'active' : ''}.svg`} className='w-5 h-5' alt='SPL' /> */}
            <MdDragIndicator className={`w-5 h-5 ${values.move ? ' text-primary ' : ''}`} />
          </span>
          <span className={`block font-light mt-2 ${values.move ? ' text-primary' : ''}`}>Move Balloons</span>
        </Button>
        <div className={`w-px bg-gray-500 ${open ? ' mx-1 ' : ' mx-2 '}`}></div>
        <Button onClick={() => console.log('screensize')} disabled={true}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/screensize.svg' className='w-5 h-5' alt='Screen Size' />
          </span>
          <span className='block font-light mt-2'>Screen Size</span>
        </Button>
        <Button onClick={() => console.log('fitscreen')} disabled={true}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/fitscreen.svg' className='w-5 h-5' alt='FitScreen' />
          </span>
          <span className='block font-light mt-2'>FitScreen</span>
        </Button>
        <div className={`w-px bg-gray-500 ${open ? ' mx-1 ' : ' mx-2 '}`}></div>

        <Button onClick={() => handleRotateBack()}  disabled={true}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/rotateleft.svg' className='w-4.5 h-4.5' alt='Rotate Left' />
          </span>
          <span className='block font-light mt-2'>Rotate Left</span>
        </Button>
        <Button onClick={() => handleRotateFront()}  disabled={true}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/rotateright.svg' className='w-4.5 h-4.5' alt='Roatate Right' />
          </span>
          <span className='block font-light mt-2'>Rotate Right</span>
        </Button>
        <div className={`w-px bg-gray-500 ${open ? ' mx-1 ' : ' mx-2 '}`}></div>


        <Button onClick={() => handleAlignLeft()} className={`${values.align === 'left' ? 'text-primary' : ''}`}>
          <span className='flex justify-center'>
            <FaAlignLeft className={`${values.align === 'left' ? 'text-primary' : ''} w-5 h-5`} />
          </span>
          <span className='block font-light mt-2'>Align Left</span>
        </Button>
        <Button onClick={() => handleAlignRight()} className={`${values.align === 'right' ? 'text-primary' : ''}`}>
          <span className='flex justify-center'>
            <FaAlignRight className={` w-5 h-5`} />
          </span>
          <span className='block font-light mt-2'>Align Right</span>
        </Button>
        <div className={`w-px bg-gray-500 ${open ? ' mx-1 ' : ' mx-2 '}`}></div>


        <Button onClick={handleZoomOut}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/minus.svg' className='w-5 h-5' alt='Zoom Out' />
          </span>
        </Button>
        <div className='flex items-center'>
          <p className='mx-2'>{parseInt(values.scale)}%</p>
        </div>
        <Button onClick={handleZoomIn}>
          <span className='flex justify-center'>
            <img src='/assets/toolbaricon/plus.svg' className='w-5 h-5' alt='Zoom In' />
          </span>
        </Button>

        <div className={`flex items-center ${open ? ' pr-1 ' : ' pr-2 '}`}>
          <InputWithIcon
            type={'number'}
            value={values?.fontSize}
            className={"w-20 pr-0"}
            onChange={handleFontSize}
          />
        </div>

        <div className={`w-px bg-gray-500 ${open ? ' mx-1 ' : ' mx-2 '}`}></div>

        {/* <Button onClick={() => console.log('undo')}>
        <span className='flex justify-center'>
          <img src='/assets/toolbaricon/undo.svg' className='w-5 h-5' alt='Undo' />
        </span>
      </Button>
      <Button onClick={() => console.log('redo')}>
        <span className='flex justify-center'>
          <img src='/assets/toolbaricon/redo.svg' className='w-5 h-5' alt='Redo' />
        </span>
      </Button>
      <div className={`w-px bg-gray-500 ${open ? ' mx-1 ' : ' mx-2 '}`}></div> */}
        <Button
          className='pl-0.5 pr-0.5 flex justify-center items-center gap-3 text-2xl text-primary font-bold'
          onClick={() => handleExport()}
        >
          <LuUpload /> Export
          {/* <FaChevronDown /> */}
        </Button>
      </div >
    </div>
  );
}