import React, { useState } from "react";
import { Button, InputWithIcon, OTPInput, PrimaryButton } from "src/components";
import { useNavigate } from "react-router-dom";
import { CiMail } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GoLock } from "react-icons/go";
import { Show } from "src/helpers/Show";
import { signUpApi, signUpUpdatePasswordApi, validateOtpApi } from "src/apiUtils/apiPath";
import { notifyError } from "src/components/toast";
import { checkEmpty } from "src/commonUtils";

export default function App() {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState(false);
  const [cnfPasswordType, setCnfPasswordType] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false)
  const [buttonName, setButtonName] = useState('Continue')
  const [errorMsg, setErrorMessage] = useState("")
  const [signUpData, setSignUpData] = useState({
    email: '',
    password: '',
    otp: '',
    confirm_password: ''
  })

  const signUp = async () => {
    try {
      if (!checkEmpty(signUpData.email)) return
      setLoading(true);
      const res = await signUpApi(signUpData);
      setLoading(false);
      if (res.status) {
        setShowEmail(false)
        setShow(false)
        setShowOtp(true)
        setButtonName('Verify OTP')
      } else {
        notifyError(res?.message || "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      notifyError("An error occurred during sign-up");
    }
  };

  const validateOtp = async () => {
    try {
      if (!checkEmpty(signUpData.email) || !checkEmpty(signUpData.otp) || signUpData?.otp?.length !== 6) return
      const res = await validateOtpApi(signUpData);
      setLoading(false);
      if (res.status) {
        setShowOtp(false)
        setShow(false)
        setShowPassword(true)
        setButtonName('Verify OTP')
      } else {
        notifyError(res?.message || "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      notifyError("An error occurred while validating OTP");
    }
  };

  const validatePassword = async () => {
    try {
      if (!checkEmpty(signUpData.email) || !checkEmpty(signUpData.password) || signUpData.password !== signUpData.confirm_password) return
      const res = await signUpUpdatePasswordApi(signUpData);
      setLoading(false);
      if (res.status) {
        navigate('/login')
      } else {
        notifyError(res?.message || "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      notifyError("An error occurred while updating the password");
    }
  };



  const checkButton = async () => {
    setShow(true)
    if (showEmail) {
      signUp()
    } else if (showPassword) {
      validatePassword()
    } else if (showOtp) {
      validateOtp()
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      {/* Left Section */}
      <div className="flex flex-col justify-center items-center p-4">
        <div className="text-center mb-3 text-2xl">
          Create an account if not registered yet
        </div>
        <div className="w-full max-w-md p-6 bg-white shadow-lg rounded-2xl border border-gray-200">
          <div className="text-center text-2xl font-semibold mb-6">Sign Up</div>
          <Show.When isTrue={showEmail || showOtp}>
            <Button
              className="flex items-center justify-center w-full bg-transparent text-tech-t475467 border border-tech-t475467 py-3 mb-4 rounded-full"
              onClick={() => console.log("Google Sign-In")}
            >
              <FcGoogle className="mr-2" /> Sign Up With Google
            </Button>
            <div className="flex items-center justify-center mb-6">
              <div className="border-b border-gray-300 flex-grow mx-2"></div>
              <span className="bg-white px-2 text-gray-500">or</span>
              <div className="border-b border-gray-300 flex-grow mx-2"></div>
            </div>
          </Show.When>
          <Show>
            <Show.When isTrue={showEmail}>
              <div className="mb-4">
                <label className="block font-medium mb-2">
                  Email Address
                  <InputWithIcon
                    startIcon={<CiMail />}
                    className="w-full"
                    placeholder="Enter your email"
                    value={signUpData.email}
                    onChange={(event) => setSignUpData(prev => ({ ...prev, email: event.target.value }))}
                  />
                </label>
              </div>
            </Show.When>
            <Show.When isTrue={showPassword}>
              <div className="mb-6">
                <label className="block font-medium mb-2">
                  Password
                  <InputWithIcon
                    type={passwordType ? "text" : "password"}
                    startIcon={<GoLock />}
                    endIcon={
                      <button
                        type="button"
                        onClick={() => setPasswordType(!passwordType)}
                        className="focus:outline-none"
                      >
                        {passwordType ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    }
                    className="w-full"
                    placeholder="Enter your password"
                    value={signUpData.password}
                    onChange={(event) => setSignUpData(prev => ({ ...prev, password: event.target.value }))}
                  />
                </label>
              </div>

              <div className="mb-6">
                <label className="block font-medium mb-2">
                  Confirm Password
                  <InputWithIcon
                    type={cnfPasswordType ? "text" : "password"}
                    startIcon={<GoLock />}
                    endIcon={
                      <button
                        type="button"
                        onClick={() => setCnfPasswordType(!cnfPasswordType)}
                        className="focus:outline-none"
                      >
                        {passwordType ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    }
                    className="w-full"
                    placeholder="Enter your password"
                    value={signUpData.confirm_password}
                    onChange={(event) => setSignUpData(prev => ({ ...prev, confirm_password: event.target.value }))}
                  />
                </label>
              </div>
            </Show.When>

            <div className="mb-6">
              <label className="block font-medium mb-3">
                Verify OTP
                <OTPInput
                  length={6}
                  value={signUpData.otp}
                  onChange={(event) => setSignUpData(prev => ({ ...prev, otp: event }))}
                />
              </label>
            </div>
          </Show>
          {show && (
            <p className="font-semibold mt-2 text-red-600 text-xs">
              {errorMsg}
            </p>
          )}
          <PrimaryButton
            className="w-full text-white py-3 rounded-xl font-bold mt-6 mb-4"
            onClick={() => checkButton()}
          >
            {buttonName}
          </PrimaryButton>
        </div>
        <div className="text-center mt-6 text-gray-900 mb-3">
          Already have an account?
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/login")}
          >
            {" "}
            Sign In
          </span>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex flex-col justify-center items-center bg-homeBackground p-4">
        <h1 className="block text-3xl md:text-5xl font-bold text-white mb-4 text-center">
          Let's begin your{" "}
          <span className="text-primary">AI</span> powered experience!
        </h1>
        <img
          src="/assets/signup/banner.jpg"
          alt="banner"
          className="max-w-full w-[651] h-[421px]"
        />
      </div>
    </div>
  );
}
