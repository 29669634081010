import React, { useEffect, useState } from "react";
import { InputWithIcon, NavBarDashboard, Button, SimpleDatatable, NotFound } from "src/components";
import { FaListUl } from "react-icons/fa6";
import { CiGrid41, CiSearch } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { getAllProjectListApi } from "src/apiUtils/apiPath";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import { Show } from "src/helpers/Show";

export default function App() {
  const [search, setSearch] = useState('')
  const [projectList, setProjectList] = useState([]);
  const [view, setView] = useState('grid'); // 'grid' or 'list'
  const navigate = useNavigate()

  const TABLE_HEAD = [
    { value: "name", label: "Name" },
    { value: "project_name", label: "Project Name" },
    { value: "created", label: "Created Date" },
  ];

  useEffect(() => { handleProjectList() }, [search]);

  const handleProjectList = async () => {
    try {
      const res = await getAllProjectListApi({search})
      if (res.status) {
        setProjectList(res.entity.map((e) => {
          return {
            ...e,
            project_name: e.name,
            image_name: e?.image_name,
            image: e.thumbnail_path,
            updated_at: moment(e.updated_at).fromNow(),
            created_at: moment(e.created_at).format("DD-MM-YYYY")
          }
        }))
      } else {
        setProjectList([])
      }
      console.log('images', res)
    } catch (err) {
      console.log("Error------------------------>", err);
    }
  }

  return (
    <div>
      <NavBarDashboard refreshProject={() => handleProjectList()} />
      <div className="text-2xl font-semibold mb-4">Projects</div>
      <div className="md:flex md:justify-between items-center mb-4">
        <div className="flex gap-3">
          <InputWithIcon
            className="border-none bg-gray-50 px-4 py-2"
            placeholder="Search by Project Name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            endIcon={
              // <Button className="p-2">
              <CiSearch />
              // </Button>
            }
          />
        </div>
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => setView('grid')}
            className={`border p-2 ${view === 'grid' ? 'bg-primary text-white' : ''}`}
          >
            <CiGrid41 />
          </Button>
          <Button
            className={`border p-2 ${view === 'list' ? 'bg-primary text-white' : ''}`}
            onClick={() => setView('list')}
          >
            <FaListUl />
          </Button>
          <Button className="border p-2" onClick={() => console.log("console")}>
            <BiSortAlt2 />
          </Button>
        </div>
      </div>
      <Show>
        <Show.When isTrue={projectList.length > 0}>
          <Show>
            <Show.When isTrue={view === 'grid'}>
            <div className={'grid grid-cols-1 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6  gap-4'}>
                {projectList.map((project) => (
                  <div key={project.project_image_id} className="border p-4 rounded-lg shadow-md" onClick={() => navigate(`/dashboard/project/image-list?id=${project.id}`)}>
                    <div className={`w-full ${view === 'grid' ? 'h-48' : 'h-32'} mb-3`}>
                      {/* <FaFolder className="w-full h-full text-primary" /> */}
                      {/* <FaDiagramProject className="w-full h-full text-primary" /> */}
                      <LiaProjectDiagramSolid className="w-full h-full text-primary" />
                    </div>
                    <div className="flex flex-col items-start space-y-2">
                      <div className="flex justify-between w-full">
                        <div className="text-lg overflow-auto">{project.project_name}</div>
                      </div>

                      <div className="flex justify-between w-full">
                        <div className="text-sm text-gray-500">{project.created_at}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Show.When>
            <Show.Else>
              <div className={'flex flex-col gap-4'}>
                <SimpleDatatable
                  headLabel={TABLE_HEAD}
                >
                  {projectList.map((project) => (
                    <React.Fragment key={project}>
                      <div className=' h-3'>
                      </div>
                      <tr className="border-2 border-tech-tEAECF0" onClick={() => navigate(`/dashboard/project/image-list?id=${project.id}`)}>
                        <td className="flex gap-3 items-center p-3 cursor-pointer">
                          <div className={`rounded-xl shadow-sm`}>
                          <LiaProjectDiagramSolid className=" text-primary h-20 w-20 " />
                            {/* <img src={project.image} alt={project.image_name} className={`object-cover h-32 w-48 rounded-lg `} loading="lazy" /> */}
                          </div>
                          {/* <div className="mb-2">{project.image_name}</div> */}
                        </td>
                        <td className="p-3 text-nowrap cursor-pointer">
                          {project.project_name}
                        </td>
                        <td className="p-3 text-nowrap cursor-pointer">
                          {project.created_at}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </SimpleDatatable>
              </div>
            </Show.Else>
          </Show>
        </Show.When>
        <Show.Else>
          <NotFound message={"No data found in projects"} />
        </Show.Else>
      </Show >
    </div >
  );
}
