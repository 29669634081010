// s3Service.js
import { S3Client, PutObjectCommand, DeleteObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';

// Initialize the S3 client
const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_REGION, // Ensure you use environment variables for security
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    }
});

/**
 * Uploads a file to AWS S3 bucket.
 * @param {Object} file - File data containing name, mimetype, and data buffer.
 * @param {string} path - Path in the S3 bucket where file will be stored.
 * @returns {Promise<Object>} - Promise resolving to S3 upload result.
 */
export const uploadToS3 = async (file, path) => {
    try {
        if (!file) {
            throw new Error("No file specified.");
        }

        const { name, mimetype, data } = file;
        const fileExtension = name.split('.').pop();

        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: `${path}/${new Date().getTime()}-${path}.${fileExtension}`,
            Body: data,
            ContentType: mimetype
        };

        const result = await s3.send(new PutObjectCommand(params));
        console.log("S3 file uploaded successfully --->", result);
        return result;
    } catch (err) {
        console.error("S3 file upload failed --->", err);
        throw err;
    }
};

/**
 * Deletes a file from AWS S3 bucket.
 * @param {string} key - Key of the file to delete in the S3 bucket.
 * @returns {Promise<Object>} - Promise resolving to S3 delete result.
 */
export const deleteFromS3 = async (key) => {
    try {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: key
        };

        const result = await s3.send(new DeleteObjectCommand(params));
        console.log("S3 file deleted successfully --->", result);
        return result;
    } catch (err) {
        console.error("S3 file delete failed --->", err);
        throw err;
    }
};

/**
 * Retrieves a file from AWS S3 bucket using an S3 URL.
 * @param {string} url - The S3 URL of the file to retrieve.
 * @returns {Promise<Buffer>} - Promise resolving to the file data as a Buffer.
 */
export const getFileFromS3 = async (url) => {
    try {
        const urlObj = new URL(url);
        const key = decodeURIComponent(urlObj.pathname.substring(1)); // Remove leading slash
        
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: key
        };
        
        const result = await s3.send(new GetObjectCommand(params));
        console.log("Requested  file from S3 bucket --->", result);
        console.log(url)

        // Convert the result.Body (a readable stream) into a Buffer
        const chunks = [];
        for await (const chunk of result.Body) {
            chunks.push(chunk);
        }
        // return Buffer.concat(chunks);
        const blob = new Blob(chunks, { type: result.ContentType });
        return blob;
    } catch (err) {
        console.error("Requested file from S3 bucket error --->", err);
        throw err;
    }
};
