import React, { useEffect, useState } from 'react';
import { FileUploadConvertToJpeg, InputWithIcon, Modal, PrimaryButton } from 'src/components';
import { checkWhetherImageExistApi, updateProjectImageApi } from 'src/apiUtils/apiPath';
import { BsCloudUpload } from "react-icons/bs";
import { notifyError, notifySuccess, notifyInfo } from '../toast';
import { UploadImageValidation } from 'src/validation/AllValidationFunctions';

export default function CreateProject({ refreshProject, onDecline, id }) {
    const [modal, setModal] = useState(true)
    const [showError, setShowError] = useState(false)

    const [projectData, setProjectData] = useState({
        file: '',
        image_name: '',
        id
    })
    const [validation, setValidation] = useState({
        file: [],
        image_name: []
    })
    useEffect(() => {
        setValidation(UploadImageValidation(projectData, validation))
    }, [projectData])

    const handleUpdateProjectImage = async () => {
        try {
            setShowError(true);
            if (Object.keys(validation).some((error) => validation[error].length > 0)) return
            setShowError(false);
            notifyInfo("Creating diagram is being processed in the background. This may take a few seconds.")
            const formData = new FormData();
            Object.keys(projectData).forEach(key => formData.append(key, projectData[key]));
            handleDecline()
            const res = await updateProjectImageApi(formData)
            setModal(false)
            if (res.status) {
                setProjectData({
                    file: '',
                    image_name: ''
                })
                notifySuccess("Diagram added successfully")
                refreshProject()
                console.log('images', res)
            } else {
                notifyError("Something went wrong")
            }
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    const [checkProjectName, setCheckProjectName] = useState(false)

    const checkProjectExist = async () => {
        const res = await checkWhetherImageExistApi({ image_name: projectData.image_name, project_id: projectData.id })
        if (res.status) {
            if (res.entity.length > 0) {
                setCheckProjectName(false)
            } else {
                setCheckProjectName(true)
            }
        } else {
            setCheckProjectName(false)
        }
    }


    const handleDecline = () => {
        onDecline()
        setModal(false)
    }

    useEffect(() => {

        if (projectData?.image_name?.length > 3){
            checkProjectExist()
        }
    }, [projectData.image_name])

    return (
        <Modal
            isOpen={modal}
            title="Add Diagram"
            onDecline={() => handleDecline()}
        >
            <div>
                <div className="mb-4">
                    <label className="block font-medium mb-2">
                        Image Name
                        <InputWithIcon
                            className="w-full"
                            placeholder=""
                            onChange={(e) => setProjectData((prev) => ({ ...prev, image_name: e.target.value }))}
                            error={(validation.image_name && validation.image_name.length > 0) ? validation.image_name[0] : []}
                            show={showError}
                            onBlur={checkProjectExist}
                            redBorder={!checkProjectName && projectData?.image_name?.length > 3}
                        />
                    </label>
                </div>
                <div className="block font-medium">
                    Upload Image
                    <FileUploadConvertToJpeg
                        divclass="w-full mt-2"
                        className="w-full mt-2"
                        handleFilePreview={(e) => setProjectData(prev => ({ ...prev, file: e.file }))}
                        error={(validation.file && validation.file.length > 0) ? validation.file[0] : []}
                        show={showError}
                    >
                        <div className="bg-tech-tE3F5FF rounded-xl flex p-3 pl-5 pr-5 w-full gap-3 items-center justify-between">
                            <BsCloudUpload className="w-8 h-8" />
                            <span className=' text-xs  text-nowrap'>Drag and Drop image</span>
                            <button className="bg-white rounded-full p-1 pl-3 pr-3 text-nowrap overflow-hidden">
                                {projectData.file ? projectData.file.name : 'Upload File'}
                            </button>
                        </div>
                    </FileUploadConvertToJpeg>
                </div>
                <div className='flex justify-center'>
                    <PrimaryButton className=" mt-6 mb-5" onClick={handleUpdateProjectImage} disabled={!checkProjectName || projectData?.image_name?.length <= 3}>
                        Add Diagram
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}