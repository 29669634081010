import React, { useRef, useState, useEffect } from 'react';
import {getAnnotationPredictionApi, updateBalloonedMovedDataApi, updateOcrDetectionResultApi } from 'src/apiUtils/apiPath';

import { getFileFromS3 } from 'src/s3Service';


const IMAGE_URL = process.env.REACT_APP_AI_IMAGE_URL;

export default function ProjectEditor({ imageUrl, dataList, toolBarData, handleBaloonClick, emitTransform, handleAnnotation, id, onDecline }) {
  const canvasRef = useRef(null);
  const [transform, setTransform] = useState({
    scale: 100,
    rotate: 0,
    annotate: false,
    align: 'left',
    move: false,
    fontSize: 0
  });

  const [annotationLineWidth, setAnnotationLineWidth] = useState(0);
  const [clickableAreas, setClickableAreas] = useState([]);
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [draggedCircleIndex, setDraggedCircleIndex] = useState(null); // Index of the dragged circle

  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false); // Track drawing state
  const [image, setImage] = useState('')

  useEffect(() => {
    setTransform({
      scale: toolBarData?.scale || 100,
      annotate: toolBarData?.annotate || false,
      align: toolBarData?.align || 'left',
      move: toolBarData?.move || false,
      fontSize: toolBarData?.fontSize || 0,
      rotate: toolBarData?.rotate || 0
    });
    console.log("toolBarData?.rotate", toolBarData?.rotate)
  }, [toolBarData]);

  useEffect(() => {
    if (image) {
      highlightText();
    }
  }, [image, dataList, transform]);

  useEffect(() => {
    console.log("check initiate triggered")
    initiate()
  }, [imageUrl]);

  const initiate = async () => {
    if (!imageUrl) {
      return
    }
    const imageBlob = await getFileFromS3(imageUrl)
    setImage(URL.createObjectURL(imageBlob))

  }

  useEffect(() => {
    const img = new Image();
    img.src = `${image}`;
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      console.log('testing initiate', img.width)
      emitTransform({ ...transform, fontSize: parseInt(img.width * 0.015) });
    };
  }, [image])

  function highlightText() {
    try {
      const img = new Image();
      img.src = `${image}`;
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        // Clear the canvas before drawing the new image
        context.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = img.width;
        canvas.height = img.height;
        const radian = (transform.rotate * Math.PI) / 180
        if (transform?.rotate !== 0) {
          context.save();
          context.translate(canvas.width / 2, canvas.height / 2);
          context.rotate(radian);
          context.translate(-(canvas.width / 2), -(canvas.height / 2));
          context.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
        } else {
          context.drawImage(img, 0, 0, img.width, img.height);
        }
        const newClickableAreas = [];

        dataList.forEach((word) => {
          let { balloon_id, x1, y1, x2 } = word;
          const cx = canvas.width / 2;  // Center x
          const cy = canvas.height / 2; // Center y

          if (transform.align === 'right') {
            x1 = x2;
          }
          
          y1 = y1 - y1 * 0.07;
          // x1 = cx + (x1 - cx) * Math.cos(radian) - (y1 - cy) * Math.sin(radian);
          // x1 = cy + (x1 - cx) * Math.sin(radian) + (y1 - cy) * Math.cos(radian);
          console.log('x1', x1)
          // if (transform?.rotate !== 0) {
          //   x1 = (x1 * transform.rotate * Math.PI) / 180
          //   y1 = (y1 * transform.rotate * Math.PI) / 180
          // }

          const textWidth = context.measureText(balloon_id).width;
          const textHeight = transform.fontSize || 0;
          context.font = `${textHeight * 0.50}pt Rethink`;
          context.lineWidth = img.width * 0.001;
          setAnnotationLineWidth(img.width * 0.001);

          const circleRadius = Math.max(textWidth, textHeight) * 0.60; // Adjust size as needed
          const textX = x1 - (textWidth / 2); // Center text horizontally
          const textY = y1 + (textHeight / 4); // Center text vertically

          // Draw background circle
          context.strokeStyle = '#F5B501';
          context.fillStyle = "rgba(245, 181, 1, 0.22)";
          context.beginPath();
          context.arc(x1, y1, circleRadius, 0, 2 * Math.PI);
          context.fill();
          context.stroke();

          // Draw text
          context.fillStyle = 'black';
          context.textAlign = "left";
          context.fillText(balloon_id, textX, textY);

          newClickableAreas.push({
            data: word,
            x: x1,
            y: y1,
            size: circleRadius * 2,
          });
        });

        setClickableAreas(newClickableAreas);
      };
    } catch (err) {
      console.error("canvas error -----------------> ", err)
    }
  }

  const handleClick = (event) => {
    if (transform.annotate || transform.move) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) * (canvas.width / rect.width);
    const y = (event.clientY - rect.top) * (canvas.height / rect.height);

    clickableAreas.forEach(area => {
      const distance = Math.sqrt((x - (area.x)) ** 2 + (y - (area.y)) ** 2);
      const radius = area.size / 2;

      if (distance <= radius) {
        handleBaloonClick(area.data);
      }
    });
  };

  const getMousePosition = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    return {
      x: (e.clientX - rect.left) * (canvas.width / rect.width),
      y: (e.clientY - rect.top) * (canvas.height / rect.height)
    };
  };

  const handleMouseBalloonDown = (e) => {
    if (!transform.move) return;
    const mousePos = getMousePosition(e);
    // Check if a circle is clicked for dragging
    clickableAreas.forEach((area, index) => {
      const distance = Math.sqrt((mousePos.x - area.x) ** 2 + (mousePos.y - area.y) ** 2);
      const radius = area.size / 2;
      if (distance <= radius) {
        setDraggedCircleIndex(index); // Start dragging the clicked circle
        setIsDragging(true);
      }
    });
  };

  const handleMouseBalloonMove = (e) => {
    if (!transform.move) return;
    highlightText();
    drawCircles();
  };

  const drawCircles = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    clickableAreas.forEach((area) => {
      context.strokeStyle = '#F5B501';
      context.fillStyle = "rgba(245, 181, 1, 0.22)";
      context.beginPath();
      context.arc(area.x, area.y, area.size / 2, 0, 2 * Math.PI);
      context.fill();
      context.stroke();
    });
  };

  const handleMouseBalloonUp = async (e) => {
    if (!transform.move) return;
    setIsDragging(false);
    const mousePos = getMousePosition(e);
    if (isDragging && !!draggedCircleIndex) {
      console.log(draggedCircleIndex)
      const xDiff = clickableAreas[draggedCircleIndex].x - mousePos.x
      const yDiff = clickableAreas[draggedCircleIndex].y - mousePos.y
      console.log('yDiff, xDiff', yDiff, xDiff, draggedCircleIndex, clickableAreas[draggedCircleIndex]?.data)

      const { x1, x2, y1, y2, id } = clickableAreas[draggedCircleIndex]?.data
      console.log(clickableAreas[draggedCircleIndex]?.data)
      const requestData = {
        x1: parseInt(x1) - parseInt(xDiff),
        x2: parseInt(x2) - parseInt(xDiff),
        y1: parseInt(y1) - parseInt(yDiff),
        y2: parseInt(y2) - parseInt(yDiff),
        id
      }
      // Redraw canvas with the updated circle position
      const res = await updateBalloonedMovedDataApi(requestData);
      // emitTransform({ ...transform, move: false })
      if (res.status) {
        onDecline();
      } else {
        console.error("Something went wrong");
      }
      setDraggedCircleIndex(null);
    }
  };

  const handleMouseAnnotateDown = (e) => {
    if (!transform.annotate) return;
    const mousePos = getMousePosition(e);
    setStartX(mousePos.x);
    setStartY(mousePos.y);
    setIsDrawing(true);  // Start drawing
  };

  const handleMouseAnnotateMove = (e) => {
    if (!transform.annotate || !isDrawing) return;  // Only draw when the mouse is down

    const mousePos = getMousePosition(e);
    const x2 = mousePos.x;
    const y2 = mousePos.y;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    // Redraw image and existing dataList
    highlightText();

    // Draw the new rectangle
    context.lineWidth = annotationLineWidth;
    context.strokeStyle = "#F5B501";
    context.strokeRect(startX, startY, x2 - startX, y2 - startY);
  };

  const handleMouseAnnotateUp = (e) => {
    if (!transform.annotate || !isDrawing) return;
    setIsDrawing(false);  // Stop drawing

    const mousePos = getMousePosition(e);
    const x2 = mousePos.x;
    const y2 = mousePos.y;

    // Save the new annotation to dataList
    // handleDrawingClick({
    //   x1: startX,
    //   y1: startY,
    //   x2,
    //   y2,
    //   balloon_id: 33
    // })
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    // Ensure that coordinates and dimensions are within canvas bounds
    const tempWidth = Math.max(x2 - startX, 0);
    const tempHeight = Math.max(y2 - startY, 0);

    // Handle potential negative start coordinates by adjusting them
    const adjustedStartX = Math.max(startX, 0);
    const adjustedStartY = Math.max(startY, 0);

    // Create a temporary canvas with the correct dimensions
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = tempWidth;
    tempCanvas.height = tempHeight;
    const tempContext = tempCanvas?.getContext("2d");

    try {
      const imageData = context.getImageData(adjustedStartX, adjustedStartY, tempWidth, tempHeight);

      tempContext.putImageData(imageData, 0, 0);
      const dataURL = tempCanvas.toDataURL();
      console.log(dataURL); // Use this data URL as needed
      tempCanvas.toBlob(async (blob) => {
        const annotationData = {
          x1: startX,
          y1: startY,
          x2,
          y2,
          file: blob,
          classname: 'Others'
        }
        const formData = new FormData();
        Object.keys(annotationData).forEach(key => formData.append(key, annotationData[key]));
        formData.append('id', id)
        const res = await getAnnotationPredictionApi(formData);
        emitTransform({ ...transform, annotate: false })
        if (res.status === "success") {
          Object.keys(res).forEach(key => formData.append(key, res[key]));
          const updateOcrRes = await updateOcrDetectionResultApi(formData);
          if (updateOcrRes.status) {
            onDecline();
          } else {
            console.error("Something went wrong");
          }
        } else {
          console.error("Something went wrong");
        }
      });
    } catch (error) {
      console.error("Error getting image data:", error);
    }
  };

  return (
    <div className='overflow-hidden border border-gray-500'>
      <div className={`max-h-screen overflow-auto ${transform.annotate ? ' cursor-crosshair ' : ''}`} key={toolBarData}>
        <canvas
          ref={canvasRef}
          onClick={handleClick}
          onMouseDown={(e) => {
            handleMouseAnnotateDown(e)
            handleMouseBalloonDown(e)
          }}
          onMouseMove={(e) => {
            handleMouseAnnotateMove(e)
            handleMouseBalloonMove(e)
          }}
          onMouseUp={(e) => {
            handleMouseAnnotateUp(e)
            handleMouseBalloonUp(e)
          }}
          style={{
            transform: `scale(${transform.scale / 100})`,
            transformOrigin: 'top left',
            width: '100%',
            height: 'auto',
            overflow: 'auto'
          }}
          key={imageUrl || toolBarData || image || transform} // Force re-render when imageUrl changes
        />
      </div>
    </div>
  );
}
