import React, { useEffect, useState } from "react";
import { Button, InputWithIcon, OTPInput, PrimaryButton } from "src/components";
import { useNavigate } from "react-router-dom";
import { CiMail } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GoLock } from "react-icons/go";
import { SignInValidation } from "src/validation/AllValidationFunctions";
import { notifyError } from "src/components/toast";
import { signInApi } from "src/apiUtils/apiPath";

export default function App() {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState(false);

  const [showError, setShowError] = useState(false)


  const [formValue, setFormValue] = useState({
    email: '',
    password: ''
  })

  const [validation, setValidation] = useState({
    email: [],
    password: []
  })

  const handleLogin = async () => {
    try {
      setShowError(true);
      if (Object.keys(validation).some((error) => validation[error].length > 0)) return
      setShowError(false);
      const result = await signInApi(formValue)
      if (result.status) {
        const UserData = result?.entity?.userData
        localStorage.setItem("UserLoginData", JSON.stringify(UserData))
        localStorage.setItem("user_first_name", UserData.first_name)
        localStorage.setItem("user_last_name", UserData.last_name)
        localStorage.setItem("user_phone_no", UserData.phone_no)
        localStorage.setItem("user_email", UserData.email)
        localStorage.setItem("DeviceToken", UserData.token)
        navigate('/dashboard')
      } else {
        notifyError(result.message)
      }
    } catch (err) {
      console.log("login page api", err)
    }
  }

  useEffect(() => {
    setValidation(SignInValidation(formValue, validation))
    console.log('validationvalidationvalidationvalidation', validation, formValue)
  }, [formValue])


  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      {/* Left Section */}
      <div className="flex flex-col justify-center items-center p-4">
        <div className="w-full max-w-md p-6 bg-white shadow-lg rounded-2xl border border-gray-200">
          <div className="text-center text-2xl font-semibold mb-6">Sign In</div>
          <Button
            className="flex items-center justify-center w-full bg-transparent text-tech-t475467 border border-tech-t475467 py-3 mb-4 rounded-full"
            onClick={() => console.log("Google Sign-In")}
          >
            <FcGoogle className="mr-2" /> Sign In With Google
          </Button>
          <div className="flex items-center justify-center mb-6">
            <div className="border-b border-gray-300 flex-grow mx-2"></div>
            <span className="bg-white px-2 text-gray-500">or</span>
            <div className="border-b border-gray-300 flex-grow mx-2"></div>
          </div>
          <div className="mb-6">
            <label className="block font-medium mb-2">
              Email Address
              <InputWithIcon
                startIcon={<CiMail />}
                className="w-full"
                placeholder="Enter your email"
                onChange={(e) => setFormValue((prev) => ({ ...prev, email: e.target.value }))}
                error={validation.email[0]}
                show={showError}
              />
            </label>
          </div>
          <div className="mb-6">
            <label className="block font-medium mb-2">
              Password
              <InputWithIcon
                type={passwordType ? "text" : "password"}
                startIcon={<GoLock />}
                endIcon={
                  <button
                    type="button"
                    onClick={() => setPasswordType(!passwordType)}
                    className="focus:outline-none"
                  >
                    {passwordType ? <FaEye /> : <FaEyeSlash />}
                  </button>
                }
                className="w-full"
                placeholder="Enter your password"
                value={formValue.password}
                onChange={(e) => setFormValue((prev) => ({ ...prev, password: e.target.value }))}
                error={validation.password[0]}
                show={showError}
              />
            </label>
          </div>
          <div className="text-center mt-6 text-gray-500">
            Forgot Password?
          </div>
          <PrimaryButton
            className="w-full text-white py-3 rounded-xl font-bold mt-6 mb-6"
            onClick={() => handleLogin()}
          >
            Log In
          </PrimaryButton>
        </div>
        <div className="text-center mt-6 text-gray-500 mb-3">
          Already have an account?
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            {" "}
            Sign Up
          </span>
          .&nbsp;&nbsp;
          <span
            className="text-blue-600 cursor-pointer underline"
            onClick={() => navigate("/")}
          >
            {" "}
            Back to Home
          </span>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex flex-col justify-center items-center bg-homeBackground p-4">
        <h1 className="block text-3xl md:text-5xl font-bold text-white mb-4 text-center">
          Let's begin your{" "}
          <span className="text-primary">AI</span> powered experience!
        </h1>
        <img
          src="/assets/signup/banner.jpg"
          alt="banner"
          className="max-w-full w-[651] h-[421px]"
        />
      </div>
    </div>
  );
}
