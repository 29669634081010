/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiHomeAlt2 } from "react-icons/bi";
import { FaRegStar } from "react-icons/fa6";
import { MdThumbUpOffAlt } from "react-icons/md";
import { Show } from "src/helpers/Show";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
// import { AiOutlinePlus } from "react-icons/ai";
import { addProjectActiveApi, getAllProjectImageListApi, getAllProjectListApi } from "src/apiUtils/apiPath";




const Sidebar = ({ open }) => {
    const [subMenuList, setSubMenuList] = useState([])
    const [subMenu2List, setSubMenu2List] = useState([])

    const [modal, setModal] = useState([])

    const [routes, setRoutes] = useState([]);

    const [showProject, setShowProject] = useState(false)


    const navigate = useNavigate()

    useEffect(() => {
        setRoutes([
            { link: '/dashboard/home', name: 'Home', icon: <BiHomeAlt2 className="h-6 w-6" /> },
            { link: '/dashboard/favourite', name: 'Favourite', icon: <FaRegStar className="h-6 w-6" /> },
            { link: '/dashboard/project', name: 'Projects', icon: <MdThumbUpOffAlt className="h-6 w-6" /> }
        ]);
    }, []);

    
    const location = useLocation();
    
    const activeRoute = (routeName) => location.pathname.startsWith(routeName);
    
    useEffect(() => {
        if (!location.pathname.startsWith('/dashboard/project')) {
            setShowProject(false)
        }
    }, [location.pathname])

    const [showImages, setShowImages] = useState(true)

    const [activeProject, setActiveProject] = useState(null)

    const handleSubMenu = async (index, id) => {
        navigate(`/dashboard/project/image-list?id=${id}`)
        setActiveProject(index === activeProject ? null : index)
        try {
            const res = await getAllProjectImageListApi({ id, search: '' })
            if (res.status) {
                setSubMenu2List(res.entity)
            } else {
                setSubMenu2List([])
            }
            console.log('images', res)
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    const addProjectActive = async (project) => {
        try {
            const { id } = project
            const res = await addProjectActiveApi({ project_image_id: id })
            if (res.status) {
                localStorage.setItem("activetabid", id)
                navigate('/dashboard/project/tabs')
            } else {
                console.log("something went wrong")
            }

            console.log('images', res)
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    const subMenu2 = () => {
        return (
            subMenu2List.map((e, index) => (
                <div className="relative mb-7 ml-12 flex hover:cursor-pointer" onClick={() => addProjectActive(e)} key={index}>
                    <li
                        className={`my-[3px] flex cursor-pointer items-center transition-all duration-300 ease-in-out ${open ? 'px-8' : 'md:px-4'}`}
                    >
                        <span className={`font-medium text-white hover:text-button `}>
                            {/* {e.icon} */}
                        </span>
                        {open && (
                            <p className={`leading-1 ml-4  text-md flex font-medium text-white hover:text-button w-32 text-nowrap overflow-auto`}>
                                {e.image_name}
                            </p>
                        )}
                    </li>
                </div>
            ))
        )
    }

    const subMenu = (id) => {
        if (showProject && open) {
            return (
                subMenuList.map((e, index) => (
                    <React.Fragment key={index}>
                        <div className="relative mb-7 ml-5 flex hover:cursor-pointer">
                            <li
                                className={`my-[3px] pr flex cursor-pointer items-center transition-all duration-300 ease-in-out ${open ? 'px-8' : 'md:px-4'}`}
                                onClick={() => handleSubMenu(index, e.id)}
                            >
                                <span className={`font-medium text-white hover:text-button `}>
                                    {/* {e.icon} */}
                                </span>
                                {open && (
                                    <p className={`leading-1  text-md flex font-medium items-center text-white hover:text-button  overflow-auto`}>
                                        {activeProject !== index ? <IoIosArrowForward className="pr-2 w-6 h-6" /> : <IoIosArrowDown className="pr-2 w-6 h-6" />} <span className="w-20 overflow-auto"> {e.name}</span>
                                    </p>
                                )}
                            </li>
                            {/* <div className="flex justify-end items-center text-white">
                                <AiOutlinePlus className="w-5 h-5" />
                            </div> */}
                        </div>
                        <Show.When isTrue={showImages && activeProject === index}>
                            <div>
                                {subMenu2()}
                            </div>
                        </Show.When>
                    </React.Fragment >
                ))
            )
        }
    }

    const handleProjects = async () => {
        setShowProject(!showProject)
        try {
            const res = await getAllProjectListApi({ search: '' })
            if (res.status) {
                setSubMenuList(res.entity)
            } else {
                setSubMenuList([])
            }
            console.log('images', res)
        } catch (err) {
            console.log("Error------------------------>", err);
        }
        navigate('/dashboard/project')
    }

    return (
        routes.map((route, index) => {
            return (
                <Show key={index}>
                    <Show.When isTrue={route.name === 'Projects' && open}>
                        <div className="relative mb-7 flex hover:cursor-pointer w-full" >
                            <li
                                className={`my-[3px] flex cursor-pointer items-center transition-all duration-300 ease-in-out ${open ? 'px-8' : 'md:px-4'}`}
                                onClick={() => handleProjects()}
                            >
                                <span className={` ${activeRoute(route.link) ? "font-extrabold dark:text-white !text-primary" : "font-bold text-white hover:text-button"} `}>
                                    {route.icon}
                                </span>
                                {open && (
                                    <p className={`leading-1 ml-4 flex  text-lg ${activeRoute(route.link) ? "font-extrabold !text-primary dark:text-white" : "font-medium text-white hover:text-button"} `}>
                                        {route.name}
                                    </p>
                                )}
                            </li>
                            {/* {open && (<div className="flex justify-end items-center text-white" onClick={() => console.log('testing')}>
                                <AiOutlinePlus className="w-5 h-5" />
                            </div>)} */}

                        </div>
                        <div key={subMenuList}>
                            {subMenu()}
                        </div>
                    </Show.When>
                    <Show.Else>
                        <Link to={route.link}>
                            <div className="relative mb-7 flex hover:cursor-pointer">
                                <li
                                    className={`my-[3px] flex cursor-pointer items-center transition-all duration-300 ease-in-out ${open ? 'px-8' : 'md:px-4'}`}
                                >
                                    <span className={` ${activeRoute(route.link) ? "font-extrabold !text-primary dark:text-white" : "font-bold  hover:text-button"} `}>
                                        {route.icon}
                                    </span>
                                    {open && (
                                        <p className={`leading-1 ml-4 flex text-lg ${activeRoute(route.link) ? "font-extrabold !text-primary dark:text-white" : "font-bold text-white hover:text-button"} `}>
                                            {route.name}
                                        </p>
                                    )}

                                    {/* Profile Dropdown Menu */}
                                    {/* <Show.When isTrue={!open}>
                                <div
                                    className={`absolute -top-4 left-[calc(100%+20px)] mt-2 w-48 bg-white rounded-lg shadow-lg dark:bg-navy-800 !z-40`}
                                >
                                    <div
                                        className={`absolute top-2 -left-2 w-0 h-0 border-8 border-transparent border-r-8 border-r-white dark:border-r-navy-800`}
                                    ></div>
                                    <ul className="py-2 flex items-center justify-center hover:bg-gray-100 dark:hover:bg-navy-700">
                                        <li className="flex items-center px-4 py-2 text-sm cursor-pointer">
                                            <span className="hidden sm:block">{route.name}</span>
                                        </li>
                                    </ul>
                                </div>
                            </Show.When> */}
                                </li>
                            </div>
                        </Link>
                    </Show.Else>
                </Show>
            );
        })
    );
};

export default Sidebar;
