/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { Show } from "src/helpers/Show";
import { TbLogout } from "react-icons/tb";
import MenuList from './MenuLIst'
import { PrimaryButton } from "src/components";
import { LuUpload } from "react-icons/lu";

const Sidebar = ({ emitOpen, emitOpenPlanModal }) => {

    const username = localStorage.getItem("user_email")

    const [open, setOpen] = useState(true);

    const navigate = useNavigate()

    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const logout = () => {
        localStorage.removeItem("UserLoginData")
        localStorage.removeItem("user_first_name")
        localStorage.removeItem("user_last_name")
        localStorage.removeItem("user_phone_no")
        localStorage.removeItem("user_email")
        navigate("/home")
    }

    useEffect(() => { emitOpen(open); }, [open]);

    const location = useLocation();

    useEffect(() => {
        setProfileMenuOpen(false)
    }, [location.pathname])

    return (
        <div
            className={`p-1 sm:none fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transform transition-all duration-300 ease-in-out dark:!bg-navy-800 dark:text-white xl:!z-0 translate-x-0 ${open ? "  w-[260px] " : "sm:-translate-x-90 md:w-[70px] sm:w-0"} h-100vh`}
        >
            <div className="flex justify-end -mr-5">
                <div className="border-2 border-primary top-10 bg-white absolute p-2 rounded-full text-primary flex items-center justify-center cursor-pointer transition-transform duration-300 ease-in-out" onClick={() => setOpen(!open)}>
                    {open ? <MdArrowBackIos className="w-4 h-4" /> : <MdArrowForwardIos className="w-4 h-4" />}
                </div>
            </div>
            <Show>
                <Show.When isTrue={open}>
                    <div className="mx-[25px] mt-[35px] flex items-center cursor-pointer" onClick={() => navigate("/home")}>
                        <div className="mt-1 ml-0.5 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white pl-3 pr-3 transition-opacity duration-300 ease-in-out">
                            <div className='w-40 h-10'>
                                <img src="/assets/logo/logo.png" alt="logo" />
                            </div>
                        </div>
                    </div>
                </Show.When>
                <Show.Else>
                    <div className="mt-[25px] h-2.5 text-[26px] font-bold uppercase text-navy-700 dark:text-white pl-3 pr-3 transition-opacity duration-300 ease-in-out">
                        <div className='w-40 h-10 cursor-pointer' onClick={() => navigate("/home")}>
                            <img
                                src="/assets/logo/logo.png"
                                alt="logo"
                                className="block xs:hidden sm:hidden"
                            />
                            <img
                                src="/assets/logo/favicon.png"
                                alt="logo"
                                className="hidden sm:block xs:block"
                            />
                        </div>
                    </div>
                </Show.Else>
            </Show>
            <div className="mt-[50px] mb-7" />
            <div className="mb-auto pt-1 h-[66vh] overflow-hidden">
                <ul className="h-full overflow-y-auto">
                    <MenuList open={open} />
                </ul>
            </div>
            <div className={`block items-end ${open ? ' mx-[25px] justify-start ' : ' justify-center '} `}>
                <Show>
                    <Show.When isTrue={open}>
                        <PrimaryButton className={`mb-5 w-full flex items-center justify-center gap-4 `} onClick={() => emitOpenPlanModal()} onMouseLeave={() => setProfileMenuOpen(false)}>
                            <LuUpload className="w-5 h-5" /> {open && (<span className="flex justify-center"> Upgrade </span>)}
                        </PrimaryButton>
                    </Show.When>
                    <Show.Else>
                        <div className="flex justify-center" onClick={() => emitOpenPlanModal()} onMouseLeave={() => setProfileMenuOpen(false)}>
                            <button className={`mb-5 rounded-full bg-button-gradient p-3`}>
                                <LuUpload className="w-5 h-5" />
                            </button>
                        </div>
                    </Show.Else>
                </Show>

                <div className="relative" onMouseEnter={() => setProfileMenuOpen(true)}>
                    <div
                        className={`flex items-center gap-3 cursor-pointer rounded transition-colors w-full justify-between ${open ? '' : ' p-2'}`}
                        onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                    >
                        <div className={`flex justify-center items-center h-10 w-10 rounded-full bg-button-gradient text-white `}>
                            {username.charAt(0).toUpperCase()}
                        </div>

                        {open && (
                            <React.Fragment>
                                <div className="text-white font-semibold ml-2">
                                    {username.split('@')[0].toUpperCase()}
                                </div>
                                <div className="text-white font-semibold ml-2">
                                    <MdArrowForwardIos />
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    {/* Profile Dropdown Menu */}
                    {profileMenuOpen && (
                        <div
                            className={`absolute ${open ? '-top-20 left-[calc(100%+60px)]' : '-top-20 left-[calc(100%+20px)]'} mt-2 bg-white rounded-lg transition-transform duration-300 ease-in-out shadow-side-dark`}
                            onMouseLeave={() => setProfileMenuOpen(false)}
                        >
                            <div className="py-2">
                                <div
                                    className="flex items-center gap-3 cursor-pointer p-2 rounded transition-colors w-full"
                                    onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                                >
                                    <div className="flex justify-center items-center h-10 w-10 rounded-full bg-button-gradient text-white">
                                        {username.charAt(0).toUpperCase()}
                                    </div>
                                    <div>
                                        <div className="text-black font-semibold">
                                            {username.split('@')[0].toUpperCase()}
                                        </div>
                                        <div className="text-gray-700">
                                            {username}
                                        </div>
                                    </div>
                                </div>

                                <div className="h-[1px] bg-gray-300 my-2 mx-1"></div>

                                <div
                                    className="flex items-center justify-center px-4 py-2 text-sm text-black cursor-pointer transition-colors rounded"
                                    onClick={logout}
                                >
                                    <TbLogout className="h-6 w-6 font-medium mr-2" />
                                    <span className="hidden sm:block">Logout</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
