import React, { useEffect, useState } from "react";
import { InputWithIcon, NavBarDashboard, Button, SimpleDatatable, NotFound } from "src/components";
import { FaListUl } from "react-icons/fa6";
import { CiGrid41, CiSearch } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { IoIosStarOutline } from "react-icons/io";
import { MdOutlineStar } from "react-icons/md";
import { addProjectActiveApi, getProjectListApi, updateFavouriteApi } from "src/apiUtils/apiPath";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Show } from "src/helpers/Show";

export default function App() {
  const [search, setSearch] = useState('')

  const TABLE_HEAD = [
    { value: "name", label: "Name" },
    { value: "project_name", label: "Project Name" },
    { value: "image_name", label: "Image Name" },
    { value: "modified", label: "Modified" },
    { value: "created", label: "Created Date" },
    { value: "", label: "" }
  ];

  const [projectList, setProjectList] = useState([]);
  const [view, setView] = useState('grid'); // 'grid' or 'list'
  const navigate = useNavigate()

  useEffect(() => { handleProjectList() }, [search]);

  const handleProjectList = async () => {
    try {
      const res = await getProjectListApi({ isFavourite: true, search })
      if (res.status) {
        setProjectList(res.entity.map((e) => {
          return {
            ...e,
            project_name: e.name,
            image_name: e?.image_name,
            image: e.thumbnail_path,
            updated_at: moment(e.updated_at).fromNow(),
            created_at: moment(e.created_at).format("DD-MM-YYYY")
          }
        }))
      } else {
        setProjectList([])
      }
      console.log('images', res)
    } catch (err) {
      console.log("Error------------------------>", err);
    }
  }

  const updateFavourite = async (project_image_id) => {
    try {
      const res = await updateFavouriteApi({ project_image_id })
      if (res.status) {
        handleProjectList()
      } else {
        console.log("something went wrong")
      }

      console.log('images', res)
    } catch (err) {
      console.log("Error------------------------>", err);
    }
  }

  const addProjectActive = async (project) => {
    try {
      const { project_image_id } = project
      console.log(project_image_id)
      const res = await addProjectActiveApi({ project_image_id })
      if (res.status) {
        localStorage.setItem("activetabid", project_image_id)
        navigate('/dashboard/project/tabs')
      } else {
        console.log("something went wrong")
      }

      console.log('images', res)
    } catch (err) {
      console.log("Error------------------------>", err);
    }
  }

  return (
    <div>
      <NavBarDashboard refreshProject={() => handleProjectList()} />
      <div className="text-2xl font-semibold mb-4">Recent Projects</div>
      <div className="md:flex md:justify-between items-center mb-4">
        <div className="flex gap-3">
          <InputWithIcon
            className="border-none bg-gray-50 px-4 py-2"
            placeholder="Search by Project Name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            endIcon={
              // <Button className="p-2">
              <CiSearch />
              // </Button>
            }
          />
        </div>
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => setView('grid')}
            className={`border p-2 ${view === 'grid' ? 'bg-primary text-white' : ''}`}
          >
            <CiGrid41 />
          </Button>
          <Button
            className={`border p-2 ${view === 'list' ? 'bg-primary text-white' : ''}`}
            onClick={() => setView('list')}
          >
            <FaListUl />
          </Button>
          <Button className="border p-2" onClick={() => console.log("console")}>
            <BiSortAlt2 />
          </Button>
        </div>
      </div>
      <Show>
        <Show.When isTrue={projectList.length > 0}>
          <Show>
            <Show.When isTrue={view === 'grid'}>
              <div className={'grid grid-cols-1 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5  gap-4'}>
                {projectList.map((project) => (
                  <div key={project.project_image_id} className="border p-4 rounded-lg shadow-md">
                    <div className="text-xl font-normal mb-2 overflow-auto" onClick={() => addProjectActive(project)}>{project.project_name}</div>
                    <div className={`w-full h-48 mb-3`} onClick={() => addProjectActive(project)}>
                      <img src={project.image} alt={project.image_name} className={`object-cover w-full h-full rounded-lg `} loading="lazy" />
                    </div>
                    <div className="flex flex-col items-start space-y-2">
                      <div className="flex justify-between w-full">
                        <div className="text-lg text-nowrap overflow-auto" onClick={() => addProjectActive(project)}>{project.image_name}</div>
                        <button className="text-xl" onClick={() => updateFavourite(project.project_image_id)}>
                          {project.favourite === 1 ? (
                            <MdOutlineStar className="text-primary w-6 h-6" />
                          ) : (
                            <IoIosStarOutline className="w-6 h-6" />
                          )}
                        </button>
                      </div>

                      <div className="flex justify-between w-full" onClick={() => addProjectActive(project)}>
                        <div className="text-sm text-gray-500">Edited {project.updated_at}</div>
                        <div className="text-sm text-gray-500">{project.created_at}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Show.When>
            <Show.Else>
              <div className={'flex flex-col gap-4'}>
                <SimpleDatatable
                  headLabel={TABLE_HEAD}
                >
                  {projectList.map((project) => (
                    <React.Fragment key={project}>
                      <div className=' h-3'>
                      </div>
                      <tr className="border-2 border-tech-tEAECF0">
                        <td onClick={() => addProjectActive(project)} className="flex gap-3 items-center p-3 cursor-pointer">
                          <div className={`rounded-xl shadow-sm`}>
                            <img src={project.image} alt={project.image_name} className={`object-cover h-[130px] w-[130px] rounded-lg `} loading="lazy" />
                          </div>
                          {/* <div className="mb-2">{project.image_name}</div> */}
                        </td>
                        <td onClick={() => addProjectActive(project)} className="p-3 text-nowrap cursor-pointer">
                          {project.project_name}
                        </td>
                        <td onClick={() => addProjectActive(project)} className="p-3 text-nowrap cursor-pointer">
                          {project.image_name}
                        </td>
                        <td onClick={() => addProjectActive(project)} className="p-3 text-nowrap cursor-pointer">
                          Edited {project.updated_at}
                        </td>
                        <td onClick={() => addProjectActive(project)} className="p-3 text-nowrap cursor-pointer">
                          {project.created_at}
                        </td>
                        <td>
                          <button className="text-xl" onClick={() => updateFavourite(project.project_image_id)}>
                            {project.favourite === 1 ? (
                              <MdOutlineStar className="text-primary w-6 h-6" />
                            ) : (
                              <IoIosStarOutline className="w-6 h-6" />
                            )}
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </SimpleDatatable>
              </div>
            </Show.Else>
          </Show>
        </Show.When>
        <Show.Else>
          <NotFound message={"No data found in projects"} />
        </Show.Else>
      </Show>
    </div>
  );
}
