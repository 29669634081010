import { appApi } from './AppApi';
import { appFileUploadApi } from './AppMulitipartApi';
import { AppS3DownloadApi } from './AppS3DownloadApi';
import { AppS3StreamApi } from './AppS3StremApi';



const baseApiUrl = process.env.REACT_APP_API_URL
const baseAiUrl = process.env.REACT_APP_AI_URL
const pythonUrl = process.env.REACT_APP_PYTHON_URL

export const yoloAiApi = async (data) => await appFileUploadApi(`${baseAiUrl}detect/?threshold=0.2`, "post", data)

export const signUpApi = async (data) => await appApi(`${baseApiUrl}auth/user/signup`, "post", JSON.stringify(data))

export const validateOtpApi = async (data) => await appApi(`${baseApiUrl}auth/user/validate`, "post", JSON.stringify(data))

export const signUpUpdatePasswordApi = async (data) => await appApi(`${baseApiUrl}auth/user/password-update`, "post", JSON.stringify(data))

export const signInApi = async (data) => await appApi(`${baseApiUrl}auth/user/signin`, "post", JSON.stringify(data))

export const createProjectApi = async (data) => await appFileUploadApi(`${baseApiUrl}project/create`, "post", data)

export const getProjectListApi = async (data = false) => await appApi(`${baseApiUrl}project/list?isFavourite=${data.isFavourite || false}&search=${data.search || ''}`, "get")

export const updateFavouriteApi = async (data) => await appApi(`${baseApiUrl}favourite/add`, "post", JSON.stringify(data))

export const addProjectActiveApi = async (data) => await appApi(`${baseApiUrl}project/active`, "post", JSON.stringify(data))

export const deleteProjectActiveApi = async (data) => await appApi(`${baseApiUrl}project/active`, "delete", JSON.stringify(data))

export const getActiveProjectListApi = async () => await appApi(`${baseApiUrl}project/active/list`, "get")

export const getActiveTabDataApi = async (id) => await appApi(`${baseApiUrl}project/active/tabdata?id=${id}`, "get")

export const getAllProjectImageListApi = async (data) => await appApi(`${baseApiUrl}project/image/list?id=${data.id}&search=${data.search || ''}`, "get")

export const getAllProjectListApi = async (data) => await appApi(`${baseApiUrl}project/project/list?search=${data.search || ''}`, "get")

export const updateBalloonedResultApi = async (data) => await appApi(`${baseApiUrl}project/annotated/result`, "post", JSON.stringify(data))

export const checkWhetherItsBalloonedApi = async (id) => await appApi(`${baseApiUrl}project/check/ballooned?id=${id}`, "get")

export const updateProjectImageApi = async (data) => await appFileUploadApi(`${baseApiUrl}project/image`, "post", data)

export const updateBalloonedDataApi = async (data) => await appApi(`${baseApiUrl}project/ballooned-data`, "post", JSON.stringify(data))

export const deleteProjectBalloonDataApi = async (data) => await appApi(`${baseApiUrl}project/balloon`, "delete", JSON.stringify(data))

// export const getAnnotationPredictionApi = async (data) => await appFileUploadApi(`${baseApiUrl}annotation/predict`, "post", data)

export const getClassesMasterListApi = async (data) => await appFileUploadApi(`${baseApiUrl}master/classes`, "get")

export const updateBalloonedMovedDataApi = async (data) => await appApi(`${baseApiUrl}project/balloon/moving`, "post", JSON.stringify(data))

// export const getAnnotationPredictionApi = async (data) => await appFileUploadApi(`${pythonUrl}annotation/predict`, "post", data)

export const downloadS3Api = async (url) => await AppS3DownloadApi(`${baseApiUrl}common/s3download?url=${url}`, 'get')

export const streamS3Api = async (url) => await AppS3StreamApi(`${baseApiUrl}common/s3stream?url=${url}`, 'get')


export const getAnnotationPredictionApi = async (data) => await appFileUploadApi(`${baseAiUrl}ocr`, "post", data)

export const updateOcrDetectionResultApi = async (data) => await appFileUploadApi(`${baseApiUrl}project/ocrdetection`, "post", data)


export const checkWhetherProjectExistApi = async (name) => await appApi(`${baseApiUrl}project/exist?project_name=${name}`, "get")

export const checkWhetherImageExistApi = async (data) => await appApi(`${baseApiUrl}image/exist?image_name=${data.image_name}&project_id=${data.project_id}`, "get")

export const getPlanMasterListApi = async () => await appFileUploadApi(`${baseApiUrl}master/plan`, "get")