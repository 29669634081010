import React from 'react';

const InputWithIcon = (props) => {
    const { className, type, startIcon, endIcon, value, onChange, placeholder, error, show, disabled } = props;

    return (
        <div>
            <div className={`relative mt-2`}>
                {startIcon && (
                    <span className="absolute inset-y-0 top-0 left-0 flex items-center pl-3">
                        {startIcon}
                    </span>
                )}
                <input
                    className={`outline-none focus:ring-0 p-2 ${startIcon ? '' : ''} ${endIcon ? 'pr-12' : 'pr-4'} border border-gray-700 focus:border-blue-800 rounded-md shadow-sm text-gray-900 ${className} ${error?.valid && show ? ' border-red-600 focus:border-red-600 ' : '  '}`}
                    value={value}
                    type={type}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {endIcon && (
                    <span className="absolute inset-y-0 top-0 right-0 flex items-center pr-3">
                        {endIcon}
                    </span>
                )}
            </div>
            {/* {error?.valid && show && (
                <p className="font-semibold mt-2 text-red-600 text-xs">
                    {error?.msg}
                </p>
            )} */}
        </div>
    );
};

export default InputWithIcon;
