import PropTypes from 'prop-types';

export default function Button({
    children,
    onClick,
    className = '',
    disabled = false,
    onMouseLeave
}) {

    const getClassName = () => {
        let returnClass = ' p-2 pl-5 pr-5 font-semibold rounded-md bg-button-gradient text-white '
        returnClass += disabled ? ' opacity-40 ' : ''
        returnClass += className ? ` ${className} ` : ''
        return returnClass
    }
    return (
        <button
            className={` ${getClassName()} `}
            onClick={() => { if (!disabled) onClick() }}
            disabled={disabled}
            type="button"
            onMouseLeave={() => onMouseLeave()}
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    onClick: PropTypes.func,
    onMouseLeave: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

Button.defaultProps = {
    onClick: () => {},
    onMouseLeave: () => {},
    disabled: false,
    className: '',
};