import { useEffect, useState } from "react";
import { ProjectEditor, DataTable, ToolBar, Tab, Loader } from "src/components";
import { useNavigate } from "react-router-dom";
import { GoKebabHorizontal } from "react-icons/go";
import {
  checkWhetherItsBalloonedApi,
  deleteProjectActiveApi,
  downloadS3Api,
  getActiveProjectListApi,
  getActiveTabDataApi,
  streamS3Api,
  updateBalloonedResultApi,
  yoloAiApi
} from "src/apiUtils/apiPath";
import ProjectModal from "./projects/projectModal";
import { exportToExcel } from "src/exportUtils/excelExports";
import { getFileFromS3 } from 'src/s3Service';

export default function App() {
  const [modal, setModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [tablist, setTablist] = useState([]);
  const [toolBarData, setToolBarData] = useState({});
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [resetToolBar, setResetToolBar] = useState(false)
  const [annotationModal, setAnnotationModal] = useState(false)
  const [projectImageId, setProjectImageId] = useState('')



  const navigate = useNavigate();

  const TABLE_HEAD = [
    { value: "balloon_id", label: "Balloon ID" },
    { value: "specification", label: "Specification" },
    { value: "sub_type", label: "Type" },
    { value: "min_value", label: "Minimum Value" },
    { value: "max_value", label: "Maximum Value" },
    { value: "menu", label: "Menu" }
  ];

  const [projectId, setProjectId] = useState(0);

  const [fontSizeOption, setFontSizeOption] = useState([])

  useEffect(() => {
    handleProjectList();
  }, []);

  // useEffect(() => {
  //   if (tablist.length > 0) {
  //     getProjectData(tablist[0].id);
  //   }
  // }, [tablist]);

  const handleProjectList = async () => {
    // setIsLoading(true);
    try {
      const res = await getActiveProjectListApi();
      // setIsLoading(false);
      if (res.status) {
        const updatedTablist = res.entity.map((e) => ({
          ...e,
          project_name: e.name
        }));
        setTablist(updatedTablist);
        if (updatedTablist.length === 0) {
          navigate("/dashboard/home");
        }
      } else {
        setTablist([]);
      }
    } catch (err) {
      console.error("Error fetching project list:", err);
      setIsLoading(false);
    }
  };

  const getImageData = async (id) => {
    setIsLoading(true);
    try {
      if (!id) return;
      setProjectId(id);
      const res = await getActiveTabDataApi(id);
      setIsLoading(false);
      if (res.status) {
        setProjectImageId(res.entity.project_id)
        setDataList(
          res.entity.balloon_data.map((e, index) => ({
            ...e,
            menu: modalForm(e.id),
          }))
        );
      }
    } catch (err) {
      console.error("Error fetching image data:", err);
      setIsLoading(false);
    }
  };

  const updateBalloonedResult = async (id, result) => {
    setIsLoading(true);
    try {
      const res = await updateBalloonedResultApi({ ...result, id });
      setIsLoading(false);
      if (res.status) {
        getImageData(id);
      }
    } catch (err) {
      console.error("Error updating ballooned result:", err);
      setIsLoading(false);
    }
  };

  const handleGetResponse = async (id, image_path) => {
    try {
      setIsLoading(true);
      const response = await getFileFromS3(image_path);
      console.log('response', response)
      const formData = new FormData();
      formData.append("file", response, image_path.split("/").pop());

      const res = await yoloAiApi(formData);
      // setIsLoading(false);
      if (res.status) {
        updateBalloonedResult(id, res);
      }
    } catch (err) {
      console.error("Error in handleGetResponse:", err);
      setIsLoading(false);
    }
  };

  const getProjectData = async (id) => {
    setIsLoading(true);
    try {
      if (!id) return;
      const res = await checkWhetherItsBalloonedApi(id);
      if (res.status) {
        if (res.entity.is_ballooned) {
          getImageData(id);
        } else {
          handleGetResponse(id, res.entity.image_path);
        }
      }
    } catch (err) {
      console.error("Error fetching project data:", err);
      setIsLoading(false);
    }
  };

  const deleteProjectActive = async (project_image_id) => {
    setIsLoading(true);
    try {
      const res = await deleteProjectActiveApi({ project_image_id });
      if (res.status) {
        localStorage.removeItem("activetabid");
        handleProjectList();
      } else {
        console.error("Error deleting project");
      }
    } catch (err) {
      console.error("Error deleting project:", err);
      setIsLoading(false);
    }
  };

  const handleModalOpen = (data) => {
    setModalId(data.id);
    setModalData(data);
    setModal(true);
  };

  const handleModalClose = () => {
    setModalId(null);
    setModal(false);
    setAnnotationModal(false)
    getImageData(projectId);
  };

  const modalForm = (id) => (
    <div onClick={() => handleModalOpen(id)} className="cursor-pointer">
      <GoKebabHorizontal />
    </div>
  );
  const [annotationData, setAnnotationData] = useState({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
    file: '',
    className: ''
  })

  const handleAnnotation = (e) => {
    setAnnotationModal(true)
    console.log('check annotation', e)

    setAnnotationData(prev => ({ ...prev, ...e }));
  }


  const handleExport = () => {
    const exportData = dataList.map((e) => {
      const typeData = window.typeOption.find(x => parseInt(x.value) === parseInt(e.type))?.label || 'Others'

      return {
        "Project Name": e.name,
        "Balloon Id": e.balloon_id,
        "Type": typeData,
        "Sub Type": e.sub_type,
        "Units": 'Others',
        "Specification": e.specification ?? '--',
        "Lower Tolerance": e.lower_tolerance ?? '--',
        "Upper Tolerance": e.upper_tolerance ?? '--',
        "Minimum Value": e.min_value ?? '--',
        "Maximum Value": e.max_value ?? '--',
      }

    })
    exportToExcel(exportData, 'project1.xlsx')
  }

  return (
    <>
      <nav className="fixed z-10 w-full pt-3 -mt-7 bg-white ">
        <div key={resetToolBar}>
          <ToolBar
            emitToolBarData={setToolBarData}
            reset={() => setResetToolBar(!resetToolBar)}
            annotationData={annotationData}
            handleExport={handleExport}
            fontSizeOptionProp={fontSizeOption}
          />
        </div>
        <div className="mt-2 mb-2 overflow-hidden">
          <div className="overflow-x-auto">
            <Tab
              tabs={tablist}
              handleOnTabChange={(e) => {
                setResetToolBar(!resetToolBar)
                getProjectData(e)
              }}
              closeTab={deleteProjectActive}
            />
          </div>
        </div>
      </nav>
      <div className="mt-28" key={dataList[0]?.image_path}>
        {isLoading && <Loader />}
        <ProjectEditor
          id={projectImageId}
          imageUrl={dataList[0]?.image_path}
          dataList={dataList}
          toolBarData={toolBarData}
          handleBaloonClick={handleModalOpen}
          handleAnnotation={handleAnnotation}
          onDecline={handleModalClose}
          emitTransform={handleAnnotation}
        />
        <DataTable
          headLabel={TABLE_HEAD}
          dataList={dataList}
        />
        <ProjectModal
          isOpen={modal}
          id={modalId}
          modalData={modalData}
          title={`Balloon Number ${modalData.balloon_id}`}
          onDecline={handleModalClose}
        />
      </div>
    </>
  );
}
