import React from "react";
const FileUpload = ({
    children,
    maxWidth = '100%',
    maxHeight = '200px',
    handleFilePreview,
    disabled = false,
    divclass = '',
    className,
    error,
    show
}) => {
    const fileInputRef = React.useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && !disabled) {
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    handleFilePreview({ preview: reader.result, file });
                };
                reader.readAsDataURL(file);
            } else {
                handleFilePreview({ preview: '', file: '' });
            }
        }
        event.target.value = '';
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    handleFilePreview({ preview: reader.result, file });
                };
                reader.readAsDataURL(file);
            } else {
                handleFilePreview({ preview: '', file: '' });
            }
        }
    };

    const handleButtonClick = () => {
        if (!disabled) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className={`${divclass}`}>
            <button
                className={` ${className} ${disabled ? ' opacity-40 ' : ''}  ${error?.valid && show ? ' border border-red-600 focus:border-red-600 rounded-lg' : '  '}`}
                onClick={() => { if (!disabled) handleButtonClick() }}
                onDragOver={(e) => { if (!disabled) handleDragOver(e) }}
                onDrop={(e) => { if (!disabled) handleDrop(e) }}
                style={{ maxWidth, maxHeight }}
                disabled={disabled}
            >
                {children}
            </button>
            <input
                ref={fileInputRef}
                type="file"
                accept={"image/*"}
                onChange={(e) => handleFileChange(e)}
                hidden
            />
        </div>
    );
};

export default FileUpload;
