import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Show } from 'src/helpers/Show';

const DataTable = ({
  headLabel,
  dataList,
  isLoading = false,
  children
}) => {
  return (
    <div>
      <div className="overflow-x-scroll">
        <table className="w-full">
          <thead>
            <tr className=" border-CPAEAECF0 dark:border-navy-700 p-3">
              {headLabel.map((header, index) => (
                <th
                  key={`${header?.id} table ${index}`}
                  className={`p-3 pr-14 pb-[13px] text-start cursor-pointer text-nowrap`}
                >
                  {header?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </table>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  headLabel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    })
  ).isRequired,
  dataList: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default DataTable;
