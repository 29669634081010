import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import Notification from 'src/components/toast'

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
          <Router />
        <Notification/>
      </BrowserRouter>
    </HelmetProvider >
  );
}

export default App;
