import React, { useEffect, useState } from 'react';
import { FileUploadConvertToJpeg, InputWithIcon, Modal, PrimaryButton } from 'src/components';
import { checkWhetherProjectExistApi, createProjectApi } from 'src/apiUtils/apiPath';
import { BsCloudUpload } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { CreateProjectValidation } from 'src/validation/AllValidationFunctions';
import { notifyError, notifySuccess, notifyInfo } from 'src/components/toast'

export default function CreateProject({ refreshProject, onDecline }) {
    const [modal, setModal] = useState(true)
    const [showError, setShowError] = useState(false)

    const [projectData, setProjectData] = useState({
        file: '',
        project_name: '',
        image_name: ''
    })

    const [validation, setValidation] = useState({
        file: [],
        project_name: [],
        image_name: []
    })
    useEffect(() => {
        setValidation(CreateProjectValidation(projectData, validation))
    }, [projectData])

    const handleCreateNewProject = async () => {
        try {
            setShowError(true);
            if (Object.keys(validation).some((error) => validation[error].length > 0)) return
            setShowError(false);
            notifyInfo("Creating project is being processed in the background. This may take a few seconds.")
            const formData = new FormData();
            Object.keys(projectData).forEach(key => formData.append(key, projectData[key]));
            handleDecline()
            const res = await createProjectApi(formData)
            if (res.status) {
                setProjectData({
                    file: '',
                    project_name: '',
                    image_name: ''
                })
                notifySuccess("Project created successfully")
                refreshProject()
                console.log('images', res)
            } else {
                notifyError("Something went wrong")
            }
        } catch (err) {
            console.log("Error------------------------>", err);
        }
    }

    const [checkProjectName, setCheckProjectName] = useState(false)

    const checkProjectExist = async () => {
        const res = await checkWhetherProjectExistApi(projectData.project_name)
        if (res.status) {
            if (res.entity.length > 0) {
                setCheckProjectName(false)
            } else {
                setCheckProjectName(true)
            }
        } else {
            setCheckProjectName(false)
        }
    }

    const handleDecline = () => {
        onDecline()
        setModal(false)
    }
    useEffect(() => {
        if (projectData?.project_name?.length > 3){
            checkProjectExist()
        }
    }, [projectData.project_name])

    return (
        <Modal
            isOpen={modal}
            title="Create New Project"
            onDecline={() => handleDecline()}
        >
            <div className='overflow-hidden'>
                <div className='overflow-auto'>
                    <div className="mb-4">
                        <label className="block font-medium mb-2">
                            Project Name
                            <InputWithIcon
                                className="w-full"
                                placeholder=""
                                onChange={(e) => setProjectData((prev) => ({ ...prev, project_name: e.target.value }))}
                                error={(validation.project_name && validation.project_name.length > 0) ? validation.project_name[0] : []}
                                show={showError}
                                onBlur={checkProjectExist}
                                redBorder={!checkProjectName && projectData?.project_name?.length > 3}
                            />
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium mb-2">
                            Image Name
                            <InputWithIcon
                                className="w-full"
                                placeholder=""
                                onChange={(e) => setProjectData((prev) => ({ ...prev, image_name: e.target.value }))}
                                error={(validation.image_name && validation.image_name.length > 0) ? validation.image_name[0] : []}
                                show={showError}
                            />
                        </label>
                    </div>
                    <div className="mb-4">
                        <div className="block font-medium mb-2">
                            Team
                            <div className="flex gap-3 mt-2">
                                <div className="flex justify-center items-center h-10 w-10 rounded-full border border-primary text-primary">
                                    <FaPlus className='text-primary' />
                                </div>
                                <div className="flex -space-x-4 overflow-hidden">
                                    {['technical', 'technical', 'technical', 'technical'].map((text, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-center items-center h-10 w-10 rounded-full bg-gray-700 text-white shadow-md"
                                        >
                                            {text.charAt(0).toUpperCase()}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block font-medium">
                        Upload Image
                        <FileUploadConvertToJpeg
                            divclass="w-full mt-2"
                            className="w-full mt-2"
                            handleFilePreview={(e) => setProjectData(prev => ({ ...prev, file: e.file }))}
                            error={(validation.file && validation.file.length > 0) ? validation.file[0] : []}
                            show={showError}
                        >
                            <div className="bg-tech-tE3F5FF rounded-xl flex p-3 pl-5 pr-5 w-full gap-3 items-center justify-between">
                                <BsCloudUpload className="w-8 h-8" />
                                <span className=' text-xs  text-nowrap'>Drag and Drop image</span>
                                <button className="overflow-hidden bg-white rounded-full p-1 pl-3 pr-3 text-nowrap">
                                    {projectData.file ? projectData.file.name : 'Upload File'}
                                </button>
                            </div>
                        </FileUploadConvertToJpeg>
                    </div>
                    <div className='flex justify-center'>
                        <PrimaryButton className=" mt-6 mb-5" onClick={handleCreateNewProject} disabled={!checkProjectName}>
                            Create New Project
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}