import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from 'src/components';
import CreateProject from 'src/components/navbar_dashboard/CreateProject';
import { Show } from 'src/helpers/Show';
import { Plan } from 'src/components'


export default function App() {
    const [open, setOpen] = useState(true)

    const [openPlanModal, setOpenPlanModal] = useState(false)

    return (
        <div className={`min-h-screen w-full h-full`}
        >
            <Sidebar emitOpen={(e) => setOpen(e)} emitOpenPlanModal={() => setOpenPlanModal(true)} />
            <div className="h-full w-full bg-lightPrimary">
                <main
                    className={`min-h-screen  mx-[12px] w-full h-full flex-none transition-all xl:pr-2  ${open ? 'md:ml-[260px]' : 'md:ml-[70px]'}`}
                    style={{ maxWidth: `calc(100vw - ${open ? 260 : 70}px)` }}
                >
                    <div className="px-6 pl-10 py-4">
                        {/* <Show>
                            <Show.When isTrue={openPlanModal}>
                                <Plan isOpen={openPlanModal} onDecline={() => setOpenPlanModal(false)} />
                            </Show.When>
                            <Show.Else> */}
                                <Outlet context={{ open }} />
                            {/* </Show.Else>
                        </Show> */}
                    </div>
                </main>
            </div>
        </div>
    )
}