import React from 'react';

const SingleSelect = (props) => {
    const { className, value, onChange, placeholder, error, show, option = [] } = props;

    return (
        <div className="mt-2">
            <div className="relative">
                <select
                    className={`outline-none focus:ring-0 p-2 pl-4 pr-10 border border-gray-700 focus:border-blue-800 rounded-md shadow-sm w-full text-gray-900 ${className} ${error?.valid && show ? 'border-red-600 focus:border-red-600' : ''} bg-transparent appearance-none`}
                    value={value}
                    onChange={onChange}
                    style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='gray'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.707a1 1 0 011.414 0L10 11.086l3.293-3.379a1 1 0 011.414 1.414l-4 4.125a1 1 0 01-1.414 0l-4-4.125a1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E")`,
                        backgroundPosition: 'right 0.75rem center',
                        backgroundSize: '1.5rem 1.5rem', 
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <option value="">{placeholder || 'Select'}</option>
                    {option?.map((e, index) => (
                        <option key={index} value={e.value}>
                            {e.label}
                        </option>
                    ))}
                </select>
            </div>
            {/* {error?.valid && show && (
                <p className="font-semibold mt-2 text-red-600 text-xs">
                    {error?.msg}
                </p>
            )} */}
        </div>
    );
};

export default SingleSelect;
