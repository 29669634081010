import { validator } from './validation';

export function SignInValidation(data, validation) {
    return {
        ...validation,
        email: validator({ value: data.email, validate: ['required', 'email'] }),
        password: validator({ value: data.password, validate: ['required'] }),
    }
}

export function CreateProjectValidation(data, validation) {
    return {
        ...validation,
        file: validator({ value: data.file, validate: ['required'] }),
        project_name: validator({ value: data.project_name, validate: ['required', 'minLength'], minLength: 3 }),
        image_name: validator({ value: data.image_name, validate: ['required', 'minLength'], minLength: 3 }),
    }
}

export function UploadImageValidation(data, validation) {
    return {
        ...validation,
        file: validator({ value: data.file, validate: ['required'] }),
        image_name: validator({ value: data.image_name, validate: ['required', 'minLength'], minLength: 3 }),
    }
}
