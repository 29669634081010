// NoContentFound.js
import React from 'react';

const NoContentFound = ({ message = "No content found" }) => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        <img src='/assets/notfound/notfound.png' alt='not-found' className='w-96 h-96'/>
        <p className="text-xl font-normal -mt-12">{message}</p>
      </div>
    </div>
  );
};

export default NoContentFound;
